import _words from 'lodash/words'

import kebabCase from 'lodash/kebabCase'
import _truncate from 'lodash/truncate'

export { kebabCase }

/**
 * Checks if provided value is a string
 *
 * @param {*} value Value to be checked
 */
export function isString(value) {
  return typeof value === 'string'
}

/**
 * Performs an action with every substring that matches the regexp in a string
 *
 * @param {string} string
 * @param {regexp} regExp
 * @param {function} action
 */
export function withEveryMatch(string, regExp, action = x => x) {
  const substrings = string.match(new RegExp(regExp, 'g')) || []

  for (const substring of substrings) {
    action(substring.match(regExp))
  }
}

/**
 * Removes double spaces, enters (\n) and tabs (\t) from a string
 *
 * @param {string} string String that will be cleaned
 *
 * @returns {string}
 */
export function removeDoubleSpaces(string) {
  return _words(string, /[^, ,\n,\t]+/g).join(' ')
}

/**
 * Format a number (in bytes) as a human readable string with an appropiate uniy (KB/MB/GB, etc)
 *
 * @param {integer} bytes Number to be fomatted
 * @param {integer} decimals Indicates how many decimals are allowed
 *
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 bytes'

  const kilo = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(kilo))

  return parseFloat((bytes / Math.pow(kilo, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Converts a string intro a valid slug
 *
 * @param {String} string The string to convert in a slug
 * @param {Number} [maxLength=120] - Maximum slug string lenght
 */
export function slugify(string, maxLength = 120) {
  const slug = kebabCase(string.replace(/&|\./g, '-'))
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

  const maxIndex = slug.indexOf('-', maxLength)

  return maxIndex > 0 ? slug.substr(0, maxIndex) : slug
}

/**
 * Truncates and adds ellipsis to a string
 *
 * @param {String} string - The string to truncate
 * @param {Number} [length=20] -  A number with the maximum string length,
 * @param {Object} options - Options object (read more here https://lodash.com/docs/4.17.14#truncate).
 */
export function truncate(string, length = 20, options = {}) {
  return _truncate(string, { ...options, length })
}
