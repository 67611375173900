import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useLocalStore, observer } from 'mobx-react'

import Responsive from 'utils/Responsive'
import UIStore from './UIStore'

const siderOpenKey = 'SiderOpen'

// Contexts
export const UIContext = React.createContext()

// Provider
export const UIContextProvider = observer(({ children }) => {
  const store = useLocalStore(() => new UIStore())
  const { siderOpen } = store

  const onResize = useCallback(
    mediaQueries => store.setMediaQueries(mediaQueries),
    [store]
  )

  // Get siderOpen's value from browser's localStorage
  useEffect(() => {
    async function getSiderOpen() {
      const value = (await localStorage.getItem(siderOpenKey)) === 'true'
      store.setSiderOpen(value)
    }

    getSiderOpen()
  }, [store])

  // Save siderOpen's value when it changes to browser's localStorage
  useEffect(() => {
    localStorage.setItem(siderOpenKey, siderOpen)
  }, [siderOpen])

  return (
    <UIContext.Provider value={store}>
      <Responsive onChange={onResize}>{children}</Responsive>
    </UIContext.Provider>
  )
})

UIContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useUIContext() {
  return useContext(UIContext)
}
