import { useRef } from 'react'

import {
  admin,
  userManagers,
  projectManagers,
  churchTreasurers,
} from 'config/roles'
import { useAuthContext } from 'modules/account/data/AuthContext'

export default function useSideMenu() {
  const authStore = useAuthContext()
  const { user, rolesLoaded } = authStore
  const renderNextDivider = useRef(true)

  return [
    {
      url: '/dashboard',
      label: 'menu:dashboard',
      icon: 'tachometer-alt',
    },
    {
      divider: true,
    },
    {
      url: '/projects',
      label: 'menu:projects',
      icon: 'folders',
      roles: projectManagers,
    },
    {
      divider: true,
    },
    {
      url: '/reports',
      label: 'menu:reports',
      icon: 'file-invoice',
      roles: churchTreasurers,
    },
    {
      divider: true,
    },
    {
      url: '/entities',
      label: 'menu:entities',
      icon: 'university',
      roles: admin,
    },
    {
      divider: true,
    },
    {
      url: '/users',
      label: 'menu:users',
      icon: 'user-friends',
      roles: userManagers,
    },
    {
      url: '/groups',
      label: 'menu:groups',
      icon: 'users',
      roles: userManagers,
    },
    {
      url: '/roles',
      label: 'menu:roles',
      icon: 'id-badge',
      roles: admin,
    },
    {
      divider: true,
    },
    {
      url: '/help',
      label: 'menu:help',
      icon: 'question-circle',
    },
    {
      url: '/about',
      label: 'menu:about',
      icon: 'info-circle',
    },
  ].map((item) => {
    const { divider, roles } = item

    if (divider && renderNextDivider) {
      renderNextDivider.current = false
    } else if (
      roles === undefined ||
      (rolesLoaded &&
        user &&
        user.entityRoles.length > 0 &&
        user.isAllowed(roles))
    ) {
      renderNextDivider.current = true
    } else {
      return null
    }

    return item
  })
}
