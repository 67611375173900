import { useMemo } from 'react'

import { isObservableArray } from 'mobx'

import { isFunction } from 'utils/functions'
import { getAttribute, isSet, omit } from 'utils/objects'

/**
 * Checks if the thing is an array
 *
 * @param {array} thing
 */
export function isArray(thing) {
  return isObservableArray(thing) || Array.isArray(thing)
}

/**
 * Checks if an array is empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isEmpty(array) {
  return isArray(array) && array.length === 0
}

/**
 * Checks if an array is not empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isNotEmpty(array) {
  return !isEmpty(array)
}

export function range({ start = 0, end }) {
  return Array.from({ length: end - start }, (v, k) => k + start)
}

export function arrayOf({ model: Model, withItems: items, extra = {} }) {
  return items ? items.map((item) => new Model({ ...item, ...extra })) : null
}

/**
 * Removes empty items in an array
 *
 * @param {Array} items array of items
 */
export function removeEmpty(items) {
  return items.filter(Boolean)
}

/**
 * Conditionally reverses an array
 *
 * @param {Boolean} condition
 * @param {Array} items
 */
export function reverseIf(condition = true, items = []) {
  return condition ? items.reverse() : items
}

/**
 * Converts a two level array into a one level array
 *
 * @param {Array} items
 */
export function flatten(items) {
  return items.reduce((acc, val) => acc.concat(val), [])
}

/**
 * Returns props for a TreeSelect component
 *
 * @param object item
 * @param object options
 * @return `{children: array, hasChildrenprops: boolean}` - props for `TreeSelect`
 */
function getTreeSelectProps(item, { getChildren, label, value }) {
  const children = isFunction(getChildren) ? getChildren(item) : item.children

  return {
    children: children
      ? asSelectOptions(children, {
          asTree: true,
          getChildren,
          label,
          value,
        })
      : null,
    hasChildren: isSet(item.hasChildren)
      ? item.hasChildren
      : isArray(children) && children.length > 0,
  }
}

/**
 * Returns an array of items for a ui/Select or ui/TreeSelect
 *
 * @param {array} items
 * @param {object} options
 */
export function asSelectOptions(
  items = [],
  {
    asTree = false,
    getChildren = null,
    label = 'label',
    value = 'id',
    disabled = false,
  } = {}
) {
  return items.map((item) => ({
    text: getAttribute(item, label),
    value: getAttribute(item, value),
    disabled: isFunction(disabled) ? disabled(item) : item.disabled || disabled,
    item,
    ...(asTree ? getTreeSelectProps(item, { getChildren, label, value }) : {}),
  }))
}

/**
 * Hook version of asSelectOptions  (memoized)
 *
 * @param {array} items
 * @param {object} options
 */
export function useSelectOptions(
  items = [],
  {
    asTree = false,
    getChildren = null,
    label = 'label',
    value = 'id',
    disabled = false,
  } = {}
) {
  return useMemo(
    () =>
      asSelectOptions(items, { asTree, disabled, getChildren, label, value }),
    [asTree, disabled, getChildren, items, label, value]
  )
}

/**
 * Returns an array of items for a ui/Form/CheckboxGroup
 *
 * @param {array} items
 * @param {object} options Customize `label` and `value` fields
 *
 * @returns array
 */
export function asCheckboxOptions(
  items = [],
  { label = 'label', value = 'value' }
) {
  return items.map((item) => ({
    label: getAttribute(item, label),
    value: getAttribute(item, value),
    item,
  }))
}

/**
 * Hook version of asCheckboxOptions (memoized)
 *
 * @param {array} items
 * @param {object} options Customize `label` and `value` fields
 *
 * @returns array
 */
export function useCheckboxOptions(
  items = [],
  { label = 'label', value = 'value' } = {}
) {
  return useMemo(() => asCheckboxOptions(items, { label, value }), [
    items,
    label,
    value,
  ])
}

/**
 * Given an `array` of objects, returns a filtered version that excludes those where their `condition` attribute is `false`
 *
 * @param {array} items
 * @returns array
 */
export function getConditionalItems(items) {
  return isArray(items)
    ? items
        .filter(
          (item) => item && (!isSet(item.condition) || Boolean(item.condition))
        )
        .map((item) => ({
          ...omit(item, 'condition'),
        }))
    : []
}

/**
 * Hook version of getConditionalItems (memoized)
 *
 * @param {array} items
 * @returns array
 */
export function useConditionalItems(items) {
  return useMemo(() => getConditionalItems(items), [items])
}

/**
 * Returns an array with a separator inserted between each element in an Array.
 * @param {array} array items to intersperse
 * @param {any} separator separator item (supports a function: i.e. `(index, current) => "separator" )
 */
export function intersperse(array, separator) {
  const lastIndex = array.length - 1

  return array.reduce((acc, cur, index) => {
    acc.push(cur)
    if (lastIndex !== index) {
      acc.push(isFunction(separator) ? separator(index, cur) : separator)
    }
    return acc
  }, [])
}
