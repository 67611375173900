import React, { lazy, Suspense, useMemo } from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router'

import { useAuthContext } from 'modules/account/data/AuthContext'
import Modal from 'ui/Modal'
import Menu from 'ui/Menu'
import { useTranslation } from 'utils/TranslateUI'

import styles from './SessionMenu.module.scss'

const Dropdown = lazy(() => import('ui/Dropdown'))
const UserAvatar = lazy(() => import('ui/UserAvatar'))

function useMenuItems(t) {
  const authStore = useAuthContext()
  const history = useHistory()

  return useMemo(
    () => [
      {
        label: 'profile',
        icon: 'user',
        url: '/user/profile',
      },
      { divider: true },
      {
        label: 'common:settings',
        icon: 'cogs',
        url: '/user/settings',
      },
      { divider: true },
      {
        label: 'logout',
        icon: 'power-off',
        onClick: () => {
          Modal.confirm({
            title: t('logout'),
            content: t('logoutConfirm'),
            okText: t('logout'),
            onConfirm: () => {
              authStore.logout({
                onSuccess: () => history.push('/'),
              })
            },
            t,
          })
        },
      },
    ],
    [authStore, history, t]
  )
}

function SessionMenu({ hideName, trigger }) {
  const authStore = useAuthContext()

  const { pathname } = useLocation()
  const { t } = useTranslation(['session'])
  const menuItems = useMenuItems(t)

  const selectedKeys = useMemo(() => [pathname, `${pathname}/`], [pathname])

  if (!authStore.user) return null

  const avatar = (
    <div className="ant-dropdown-link">
      <UserAvatar user={authStore.user} hideName={hideName} />
    </div>
  )

  return (
    <div className={styles.wrap}>
      <Suspense fallback={avatar}>
        <Dropdown
          overlay={<Menu items={menuItems} selectedKeys={selectedKeys} t={t} />}
          placement="bottomRight"
          trigger={trigger}
        >
          {avatar}
        </Dropdown>
      </Suspense>
    </div>
  )
}

SessionMenu.propTypes = {
  hideName: PropTypes.bool,
  trigger: PropTypes.array,
}
SessionMenu.defaultProps = {
  trigger: ['click'],
}

export default observer(SessionMenu)
