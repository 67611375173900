import React, { Suspense, useEffect } from 'react'

import { configure as mobxConfigure } from 'mobx'
import { observer } from 'mobx-react'
import { HelmetProvider } from 'react-helmet-async'

import useSideMenu from 'config/useSideMenu'
import { AppContextProvider } from 'data/AppContext'
import initIconLibrary from 'icons/index.js'
import {
  AuthContextProvider,
  useAuthContext,
} from 'modules/account/data/AuthContext'
import {
  LocalesContextProvider,
  useLocalesContext,
} from 'modules/locale/data/LocalesContext'
import I18nProvider from 'utils/I18nProvider'
import { UIContextProvider } from 'ui/data/UIContext'
import AppLoading from 'ui/layouts/Main/AppLoading'
import LoginLayout from 'ui/layouts/Login/LoginLayout'
import MainLayout from 'ui/layouts/Main/MainLayout'

import './App.scss'
import './AntdOverrides.scss'

const APP_VERSION_CHECK_INTERVAL = 1000 * 60 * 60 // one hour

// Initialzie Icon Library
initIconLibrary()

// Enable mobx "strict mode" (mobx observables can only be modified by actions)
mobxConfigure({ enforceActions: 'observed' })

const App = observer(() => {
  const authStore = useAuthContext()
  const localesStore = useLocalesContext()
  const sideMenuItems = useSideMenu()

  useEffect(() => {
    // Check for app's new version:
    // - Check it immediately
    authStore.getClientVersion({ isInitialLoad: true })

    // - And set an interval to check it regularly
    setInterval(() => {
      authStore.getClientVersion({ isInitialLoad: false })
    }, APP_VERSION_CHECK_INTERVAL)
  }, [authStore])

  const { authenticated, dataIsLoading, user } = authStore
  const { defaultLocale, localeUI } = user?.preferences || {}

  useEffect(() => {
    localesStore.loadLocales({
      onSuccess: (locales) => {
        const locale = locales[0]

        // Prefered Contents locale
        localesStore.setCurrentLocaleByCode(defaultLocale || locale.code)

        // Prefered User Interface locale
        localesStore.setLocaleUIByCode(localeUI || locale.code)
      },
    })
  }, [defaultLocale, localesStore, localeUI])

  return (
    <UIContextProvider>
      <Suspense fallback={<span />}>
        {dataIsLoading && <AppLoading />}
        {authenticated ? (
          <MainLayout sideMenuItems={sideMenuItems} />
        ) : (
          <LoginLayout />
        )}
      </Suspense>
    </UIContextProvider>
  )
})

function Providers() {
  return (
    <LocalesContextProvider>
      <AppContextProvider>
        <AuthContextProvider>
          <I18nProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </I18nProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </LocalesContextProvider>
  )
}

export default observer(Providers)
