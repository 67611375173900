import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useLocalStore } from 'mobx-react'

import AppStore from './AppStore'

// Contexts
export const AppContext = React.createContext()

// Provider
export function AppContextProvider({ children }) {
  const store = useLocalStore(() => new AppStore())

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useAppContext() {
  return useContext(AppContext)
}
