import React from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'

import LabelIcon from 'ui/LabelIcon'

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowAltDown,
  faArrowAltRight,
  faArrowAltUp,
  faArrowDown,
  faArrowUp,
  faAt,
  faAward,
  faBadge,
  faBadgeCheck,
  faBan,
  faBars,
  faBed,
  faBell,
  faBold,
  faBoxAlt,
  faBoxesAlt,
  faCalendarAlt,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChild,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faClock,
  faCode,
  faCog,
  faCogs,
  faCompressAlt,
  faCopy,
  faCropAlt,
  faCrosshairs,
  faDatabase,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisVAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExchange,
  faExclamationTriangle,
  faExpandAlt,
  faExpandWide,
  faExpandArrows,
  faExternalLink,
  faExternalLinkSquare,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCheck,
  faFileDownload,
  faFileEdit,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFilePdf,
  faFilePlus,
  faFilePowerpoint,
  faFileTimes,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFilter,
  faFlag,
  faFolder,
  faFolders,
  faFont,
  faGlobe,
  faGripHorizontal,
  faGripVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHashtag,
  faHdd,
  faHeading,
  faHeadphones,
  faHighlighter,
  faHistory,
  faHomeAlt,
  faIdBadge,
  faImage,
  faImages,
  faIndent,
  faInfoCircle,
  faItalic,
  faKey,
  faLevelDown,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLocation,
  faLock,
  faLockOpen,
  faLongArrowDown,
  faLongArrowUp,
  faMapMarkerAlt,
  faMedkit,
  faMinus,
  faMinusCircle,
  faNetworkWired,
  faNewspaper,
  faNotEqual,
  faOutdent,
  faPalette,
  faParagraph,
  faPaste,
  faPenSquare,
  faPencilRuler,
  faPlay,
  faPlayCircle,
  faPlusCircle,
  faPowerOff,
  faProjectDiagram,
  faQuestionCircle,
  faQuoteLeft,
  faRedo,
  faRss,
  faRulerCombined,
  faSave,
  faSearch,
  faServer,
  faShareAlt,
  faShieldAlt,
  faSitemap,
  faSignIn,
  faSignOut,
  faSort,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortNumericDownAlt,
  faSortNumericUp,
  faSparkles,
  faSpinner,
  faStar,
  faStickyNote,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faSync,
  faTachometerAlt,
  faTag,
  faTags,
  faTasks,
  faText,
  faTh,
  faThList,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTrash,
  faTrashRestore,
  faUnderline,
  faUndo,
  faUniversity,
  faUnlock,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersCog,
  faVectorSquare,
  faVideo,
  faVolume,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'

const icons = [
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowAltDown,
  faArrowAltRight,
  faArrowAltUp,
  faArrowDown,
  faArrowUp,
  faAward,
  faAt,
  faBan,
  faBars,
  faBadge,
  faBadgeCheck,
  faBed,
  faBell,
  faBold,
  faBoxAlt,
  faBoxesAlt,
  faCalendarAlt,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChild,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faClock,
  faCode,
  faCog,
  faCogs,
  faCompressAlt,
  faCopy,
  faCropAlt,
  faCrosshairs,
  faDatabase,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisVAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExchange,
  faExclamationTriangle,
  faExpandAlt,
  faExpandWide,
  faExpandArrows,
  faExternalLink,
  faExternalLinkSquare,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCheck,
  faFileDownload,
  faFileEdit,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFilePdf,
  faFilePlus,
  faFilePowerpoint,
  faFileTimes,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFilter,
  faFlag,
  faFolder,
  faFolders,
  faFont,
  faGlobe,
  faGripHorizontal,
  faGripVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHashtag,
  faHdd,
  faHeading,
  faHeadphones,
  faHighlighter,
  faHistory,
  faHomeAlt,
  faIdBadge,
  faImage,
  faImages,
  faIndent,
  faInfoCircle,
  faItalic,
  faKey,
  faLevelDown,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLocation,
  faLock,
  faLockOpen,
  faLongArrowDown,
  faLongArrowUp,
  faMapMarkerAlt,
  faMedkit,
  faMinus,
  faMinusCircle,
  faNetworkWired,
  faNewspaper,
  faNotEqual,
  faOutdent,
  faPalette,
  faParagraph,
  faPaste,
  faPenSquare,
  faPencilRuler,
  faPlay,
  faPlayCircle,
  faPlusCircle,
  faPowerOff,
  faProjectDiagram,
  faQuestionCircle,
  faQuoteLeft,
  faRedo,
  faRss,
  faRulerCombined,
  faSave,
  faSearch,
  faServer,
  faShareAlt,
  faShieldAlt,
  faSitemap,
  faSignIn,
  faSignOut,
  faSort,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortNumericDownAlt,
  faSortNumericUp,
  faSparkles,
  faSpinner,
  faStar,
  faStickyNote,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faSync,
  faTachometerAlt,
  faTag,
  faTags,
  faTasks,
  faText,
  faTh,
  faThList,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTrash,
  faTrashRestore,
  faUnderline,
  faUndo,
  faUniversity,
  faUnlock,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersCog,
  faVectorSquare,
  faVideo,
  faVolume,
  faWrench,
]

export function iconsAsSelectOptions() {
  return icons.map(({ iconName }) => ({
    label: () => <LabelIcon label={iconName} icon={iconName} />,
    value: iconName,
  }))
}

// Add icons to library
export default () => {
  library.add(...icons)
}
