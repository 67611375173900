import { computed, decorate, observable } from 'mobx'

class Locale {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  name = {}
  languageCode = ''
  countryCode = ''
  isRTL = false

  constructor(args) {
    Object.assign(this, args)
  }

  get code() {
    return `${this.languageCode}-${this.countryCode.toUpperCase()}`
  }

  label = (localeUICode = 'en-US') => {
    return `${this.name[localeUICode]} (${this.code})`
  }
}

decorate(Locale, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  code: computed,

  name: observable,
  languageCode: observable,
  countryCode: observable,
  isRTL: observable,
})

export default Locale
