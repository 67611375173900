export const admin = ['admin']
export const userManagers = ['usermanager', ...admin]
export const projectManagers = ['projectmanager', ...admin]
export const churchTreasurers = ['treasurer', ...admin]

export const all = [
  ...admin,
  ...userManagers,
  ...projectManagers,
  ...userManagers,
  ...churchTreasurers,
]
