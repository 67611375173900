// These are loopback's default endpoints for resources, plus some of our custom ones (enable, disable, and restore)
export default {
  list: {
    method: 'get',
    url: '/',
    count: 'count',
  },
  add: {
    method: 'post',
    url: '/',
  },
  item: {
    method: 'get',
    url: '/{id}',
  },
  one: {
    method: 'get',
    url: '/findOne',
  },
  update: {
    method: 'patch',
    url: '/{id}',
  },
  enable: {
    method: 'post',
    url: '/{id}/enable',
  },
  disable: {
    method: 'post',
    url: '/{id}/disable',
  },
  delete: {
    method: 'delete',
    url: '/{id}',
  },
  restore: {
    method: 'post',
    url: '/{id}/restore',
  },
}
