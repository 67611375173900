import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useLocalStore } from 'mobx-react'

import { useLocalesContext } from 'modules/locale/data/LocalesContext'
import AuthStore from './AuthStore'
import { useAppContext } from 'data/AppContext'

// Contexts
export const AuthContext = React.createContext()
export const AuthDispatchContext = React.createContext()

// Provider
export function AuthContextProvider({ children }) {
  const appStore = useAppContext()
  const { lastApiCall, lastApiError } = appStore
  const localesStore = useLocalesContext()

  const store = useLocalStore(() => {
    return new AuthStore({
      appStore,
      onSetUser: ({ user }) => {
        if (user) {
          const { defaultLocale, localeUI } = user.preferences
          // Prefered Contents locale
          localesStore.setCurrentLocaleByCode(defaultLocale)
          // Prefered User Interface locale
          localesStore.setLocaleUIByCode(localeUI)
        }
      },
    })
  })

  useEffect(() => {
    store.updateSession({
      error: lastApiError,
      datetime: lastApiCall,
    })
  }, [store, lastApiCall, lastApiError])

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useAuthContext() {
  return useContext(AuthContext)
}
