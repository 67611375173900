export default {
  entities: {
    resource: 'entities',
    endpoints: {
      ancestors: {
        method: 'get',
        url: '/{id}/ancestors',
      },
    },
  },
  entityTypes: {
    resource: 'entitytypes',
    endpoints: {},
  },
  countries: {
    resource: 'countries',
    endpoints: {},
  },
}
