import React, { lazy } from 'react'

import { Route, Switch } from 'react-router-dom'

const Login = lazy(() => import('modules/account/session/Login'))
const ResetPassword = lazy(() =>
  import('modules/account/session/ResetPassword')
)
const ForgotPassword = lazy(() =>
  import('modules/account/session/ForgotPassword')
)

function PublicRouter() {
  return (
    <Switch>
      <Route path="/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route path="/request-password-reset">
        <ForgotPassword />
      </Route>
      <Route>
        <Login />
      </Route>
    </Switch>
  )
}

export default PublicRouter
