import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'

import PrivateRouter from 'routes/PrivateRouter'
import Loading from 'ui/Loading'

import { useUIContext } from '../../data/UIContext'
import BannerNotifications from './BannerNotifications'
import SessionMenu from './SessionMenu'
import SiderMenu from './SiderMenu'
import EntityModal from './EntityModal'

import styles from './MainLayout.module.scss'

const Logo = lazy(() => import('ui/Logo'))
const SiderToggle = lazy(() => import('ui/SiderToggle'))

function MainLayout({ sideMenuItems }) {
  const { smScreen, siderOpen, closeSider } = useUIContext()

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <div
          className={`${styles.wrapper} ${
            styles[`sider--${siderOpen ? 'open' : 'closed'}`]
          } sider--${siderOpen ? 'open' : 'closed'}`}
        >
          <div className={styles.header}>
            <div className={styles.title}>
              <SiderToggle
                className={styles.siderToggle}
                iconOpen="bars"
                iconClosed="bars"
                iconClass={`${styles.siderToggleIcon} ${
                  siderOpen ? styles.siderToggleIconOpen : ''
                }`}
              />
              <Logo />
            </div>
            <EntityModal />

            <SessionMenu hideName={smScreen} />
          </div>
          <div className={styles.body}>
            <div className={styles.sider}>
              <div className={styles.siderMenu}>
                <SiderMenu items={sideMenuItems} />
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.contentOverlay} onClick={closeSider} />

              <BannerNotifications />

              <Suspense fallback={<Loading />}>
                <PrivateRouter />
              </Suspense>
            </div>
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  )
}
MainLayout.propTypes = {
  sideMenuItems: PropTypes.array,
}
MainLayout.defaultProps = {
  sideMenuItems: [],
}

export default observer(MainLayout)
