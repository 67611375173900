import React, { lazy, useState, Suspense, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

import Loading from 'ui/Loading'
import Modal from 'ui/Modal'

import { useAuthContext } from 'modules/account/data/AuthContext'
import { asSelectOptions } from 'utils/arrays'
import { useTranslate } from 'modules/locale/data/LocalesContext'
import { useTranslation } from 'utils/TranslateUI'
import { useUIContext } from 'ui/data/UIContext'

const Alert = lazy(() => import('ui/Alert'))
const Button = lazy(() => import('ui/Button'))
const ButtonGroup = lazy(() =>
  import('ui/Button').then((b) => ({ default: b.Group }))
)
const TreeSelect = lazy(() => import('ui/TreeSelect'))
const ContentLocaleSwitch = lazy(() => import('./ContentLocaleSwitch'))

const styles = {
  button: {
    // background: '#e6f7ff',
    borderColor: '#91d5ff',
    color: '#1890ff',
  },
  select: {
    width: '100%',
  },
  dropdown: {
    zIndex: 2010,
  },
  alert: {
    marginBottom: '1rem',
  },
}

function EntityModal() {
  const authStore = useAuthContext()
  const { xsScreen } = useUIContext()
  const history = useHistory()
  const { t } = useTranslation(['settings'])
  const translate = useTranslate()

  const {
    currentEntity,
    dataIsLoading,
    entities,
    loadEntityChildren,
    selectedEntityId,
    setCurrentEntityById,
    setSelectedEntityId,
  } = authStore

  const [open, setOpen] = useState(false)

  const onCancel = useCallback(() => {
    setOpen(false)
    setSelectedEntityId(currentEntity.id)
  }, [setSelectedEntityId, currentEntity])

  const onSave = useCallback(() => {
    setOpen(false)
    setCurrentEntityById(selectedEntityId)
    history.push('/')
  }, [history, setCurrentEntityById, selectedEntityId])

  const onExpand = useCallback(
    ({ item, onSuccess } = {}) => {
      loadEntityChildren({ parent: item.item, onSuccess })
    },
    [loadEntityChildren]
  )

  const onModalOpen = useCallback(() => setOpen(true), [setOpen])

  const entitiesOptions = useMemo(
    () =>
      asSelectOptions(entities, {
        asTree: true,
        label: (entity) => translate(entity.name),
      }),
    [entities, translate]
  )

  if (dataIsLoading || !currentEntity) return null

  return (
    <Suspense fallback={<Loading />}>
      <div>
        <ButtonGroup>
          <Button
            disabled={dataIsLoading}
            icon="map-marker-alt"
            label={translate(currentEntity.name)}
            onClick={onModalOpen}
            style={styles.button}
            size={xsScreen ? 'small' : null}
            tooltip={t('settings:switchCurrentEntity')}
          />
          <ContentLocaleSwitch
            flagOnly={true}
            size={xsScreen ? 'small' : null}
            t={t}
          />
        </ButtonGroup>

        <Modal
          cancelText={t('cancel')}
          destroyOnClose
          okButtonProps={{
            disabled:
              !selectedEntityId || selectedEntityId === currentEntity.id,
          }}
          okText={t('changeEntityButton')}
          onCancel={onCancel}
          onOk={onSave}
          title={t('changeEntityTitle')}
          visible={open}
        >
          <Alert
            description="changeEntityWarning"
            showIcon={false}
            style={styles.alert}
            type="warning"
            t={t}
          />

          <p>
            Current Entity: <strong>{translate(currentEntity.name)}</strong>
          </p>

          <TreeSelect
            dropdownStyle={styles.dropdown}
            onChange={setSelectedEntityId}
            onExpand={onExpand}
            options={entitiesOptions}
            style={styles.select}
            suffixIcon="sitemap"
            t={t}
          />
        </Modal>
      </div>
    </Suspense>
  )
}

export default observer(EntityModal)
