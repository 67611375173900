import fetchData, { setClientToken } from 'utils/fetchData'
import { isFunction } from 'utils/functions'
import { replaceParams } from 'utils/urls'

import appConf from 'config/app'

import defaultEndpoints from './defaultEndpoints'

const { apiEndpoints } = appConf

function getRequest(endpointsConf, resourceName, endpoint, params) {
  const { resource, endpoints } = endpointsConf[resourceName]

  const { url, method, count } =
    endpoints[endpoint] || defaultEndpoints[endpoint] || {}

  if (!url) return null

  const requestParams = replaceParams(url, params)
  const requestUrl = `${apiEndpoints.rootUrl}${resource}${requestParams}`

  return {
    requestUrl,
    requestCountUrl: count ? `${requestUrl.replace(/\/$/, '')}/${count}` : null,
    method,
  }
}

export default async function apiCall({
  endpoints,
  resource,
  endpoint,
  data,
  params,
  query,
  headers,
  getCount = false,
  fileDownload = false,
  uploadFiles,
  onUploadProgress,
  onLoading,
  onStart,
  onSuccess,
  onError,
  onFinish,
}) {
  const request = getRequest(endpoints, resource, endpoint, params)

  try {
    if (request) {
      const requestUrl = getCount ? request.requestCountUrl : request.requestUrl

      // sets request client token (only if differs or is null)
      setClientToken(apiEndpoints.clientToken)

      const response = await fetchData(requestUrl, {
        loading: onLoading,
        method: request.method,
        data,
        params: query,
        headers,
        uploadFiles,
        onUploadProgress,
        fileDownload,
      })

      // Check for errors and update session time (keep alive).
      // TODO: FIX
      // this.appState.setLastApiCall(response.error ? response.error.code : null)

      if (isFunction(onStart))
        onStart(response.error ? response.error.code : null)

      if (response.error) {
        console.error(response.error, { request }) // eslint-disable-line no-console
        if (isFunction(onError)) onError(response.error)
      } else {
        if (isFunction(onSuccess)) onSuccess(response)
      }
    } else {
      onError({ error: { name: 'no-endpoint', endpoint } })
    }
    if (onFinish) onFinish()
  } catch (error) {
    console.error(error, { request }) // eslint-disable-line no-console
    onError({ error: { name: 'fetch-failed', endpoint } })
  }
}
