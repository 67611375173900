const accounts = {
  resource: 'accounts',
  endpoints: {
    login: {
      method: 'post',
      url: '/login',
    },
    logout: {
      method: 'post',
      url: '/logout',
    },
    roles: {
      method: 'get',
      url: '/{id}/roles',
    },
    entities: {
      method: 'get',
      url: '/{id}/entities',
    },
    entityById: {
      method: 'get',
      url: '/{id}/entity-by-id',
    },
    changeEmail: {
      method: 'post',
      url: '/{id}/change-email',
    },
    changePassword: {
      method: 'post',
      url: '/change-password',
    },
    resetPassword: {
      method: 'post',
      url: '/reset-password',
    },
    requestPasswordReset: {
      method: 'post',
      url: '/request-password-reset',
    },
    requestVerificationEmail: {
      method: 'post',
      url: '/request-verification-email',
    },
    enable2FA: {
      method: 'post',
      url: '/{id}/enable-2fa',
    },
    disable2FA: {
      method: 'post',
      url: '/{id}/disable-2fa',
    },
    logs: {
      method: 'get',
      url: '/{id}/logs',
      count: 'count',
    },
  },
}

const avatars = {
  resource: 'avatars',
  endpoints: {
    upload: {
      method: 'post',
      url: '/{id}/upload',
    },
  },
}

const clients = {
  resource: 'clients',
  endpoints: {
    getVersion: {
      method: 'get',
      url: '/version',
    },
  },
}

export default {
  accounts,
  avatars,
  clients,
}
