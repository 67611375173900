import React, { lazy } from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { useAppContext } from 'data/AppContext'
import { useTranslation } from 'utils/TranslateUI'
import { useAuthContext } from 'modules/account/data/AuthContext'

const Alert = lazy(() => import('ui/Alert'))
const AppOutdated = lazy(() => import('./AppOutdated'))

const data = {
  offline: {
    closable: true,
    type: 'error',
    title: 'offline',
    description: 'offlineMessage',
  },
  reconnected: {
    closable: true,
    type: 'success',
    title: 'online',
    description: 'onlineMessage',
  },
  noRole: {
    closable: false,
    type: 'warning',
    title: 'noRole',
    description: 'noRoleMessage',
  },
  appOutdated: {
    closable: false,
    type: 'warning',
    title: 'appOutdated',
    description: <AppOutdated />,
  },
}

function BannerNotifications() {
  const { isUpToDate, offline, reconnected } = useAppContext()
  const authStore = useAuthContext()

  const { user } = authStore
  const notifications = []

  const { t } = useTranslation()

  if (offline) notifications.push(data.offline)
  if (reconnected) notifications.push(data.reconnected)
  if (user && user.roles.length === 0) notifications.push(data.noRole)
  if (!isUpToDate) notifications.push(data.appOutdated)

  return notifications.map(({ closable, description, title, type }, i) => (
    <Alert
      banner
      closable={closable}
      description={description}
      key={`notification-${i}`}
      t={t}
      title={title}
      type={type}
    />
  ))
}

BannerNotifications.propTypes = {
  t: PropTypes.func,
}

export default observer(BannerNotifications)
