import { action, computed, decorate, observable } from 'mobx'

import Entity from 'modules/entities/data/Entity'
import Locale from 'modules/locale/data/Locale'
import User from 'modules/users/data/User'

import appConf from 'config/app'
import ApiStore from 'utils/api/ApiStore'
import uiMessage from 'ui/message'
import { arrayOf } from 'utils/arrays'
import { setAuthToken, setEntityId } from 'utils/fetchData'
import { isFunction } from 'utils/functions'

import { getSecret, getTwoFactorUrl } from 'utils/twoFactor'

import endpoints from './endpoints'
import entitiesEndpoints from 'modules/entities/data/endpoints'

const { clientToken, sseNofitications } = appConf.apiEndpoints
const { url } = sseNofitications
const sessionKey = 'UserSession'
const defaultEntityIdKey = 'DefaultEntityId'

class AuthStore extends ApiStore {
  // Observables

  authToken = null
  initialLoading = false
  pendingOperations = 0
  rolesLoaded = false
  session = null
  user = null

  currentEntity
  entities = []
  locales = []
  selectedEntityId

  sse
  isReconnect = false

  constructor({
    appStore,
    afterCreateSession,
    beforeDestroySession,
    onSetUser,
  }) {
    super({
      model: User,
      defaultResource: 'accounts',
      endpoints,
    })

    // Try to retrieve an exisiting session
    this.retrieveSession()

    // App Store
    this.appStore = appStore

    // Hooks
    this.afterCreateSession = afterCreateSession
    this.beforeDestroySession = beforeDestroySession
    this.onSetUser = onSetUser
  }

  // SSE

  subscribe = ({ userId }) => {
    this.isReconnect = false
    this.sse = new EventSource(`${url}/${userId}`)

    this.sse.addEventListener(
      'open',
      () => {
        if (this.isReconnect) {
          this.getClientVersion({ isInitialLoad: false })

          if (this.session) {
            this.loadProfile()
          }
        }
      },
      false
    )

    // When we got disconnected from the sse
    this.sse.addEventListener(
      'error',
      (e) => {
        if (e.target.readyState === EventSource.CLOSED) {
          this.appStore.setOffline(true)
        } else if (e.target.readyState === EventSource.CONNECTING) {
          this.isReconnect = true
        }
      },
      false
    )

    // When the client version has been updated
    this.sse.addEventListener(
      'CLIENT_VERSION_UPDATE',
      (e) => {
        const data = JSON.parse(e.data)
        if (data.token === clientToken)
          this.getClientVersion({ isInitialLoad: false })
      },
      false
    )

    // Account disabled
    this.sse.addEventListener(
      'ACCOUNT_DISABLED',
      () => {
        this.setSession(null)
      },
      false
    )

    // Account updated
    this.sse.addEventListener(
      'ACCOUNT_UPDATED',
      () => {
        this.loadProfile({ onlyProfile: true })
      },
      false
    )

    // Permissions updated
    this.sse.addEventListener(
      'PERMISSIONS_UPDATED',
      () => {
        this.loadProfile()
      },
      false
    )
  }

  unsubscribe = () => {
    if (this.sse) this.sse.close()
  }

  // Computed

  get authenticated() {
    return this.session && this.user
  }

  get dataIsLoading() {
    return this.initialLoading || this.pendingOperations > 0
  }

  // Actions

  setUser = (data, onlyProfile) => {
    if (this.user && data) {
      this.user.update(data)
    } else {
      this.user = data ? new User(data) : null
    }

    // Set user's default entity, unless only the profile is loaded
    if (this.user && !onlyProfile) this.setDefaultEntity()

    if (isFunction(this.onSetUser)) this.onSetUser(this)
  }

  setEntityRoles = (data) => {
    if (this.user) {
      this.user.setEntityRoles(data)
    }
  }

  setRolesLoaded = (loaded) => {
    this.rolesLoaded = loaded
  }

  setSelectedEntityId = (entityId) => {
    this.selectedEntityId = entityId
  }

  setSession = (session) => {
    if (!session && this.session) {
      // Unsubscribe from sse notifications for the user
      this.unsubscribe()

      if (isFunction(this.beforeDestroySession)) this.beforeDestroySession(this)
    }

    this.session = session

    this.setAuthToken(session ? session.id : null)
    this.storeSession(session)

    if (session) {
      this.loadProfile()

      // Subscribe to sse notifications for the user
      this.subscribe({
        userId: session.userId,
      })

      if (isFunction(this.afterCreateSession)) this.afterCreateSession(this)
    } else {
      this.setUser(null)
      this.setCurrentEntity(null)
      this.setRolesLoaded(false)
    }
  }

  setSessionData = (session) => {
    this.session = session
  }

  setAuthToken = (token) => {
    this.authToken = setAuthToken(token)
  }

  setLocales = (locales) => {
    this.locales = arrayOf({ model: Locale, withItems: locales })
  }

  addPendingOperation = () => {
    this.pendingOperations++
  }

  removePendingOperation = () => {
    this.pendingOperations--
    if (this.pendingOperations === 0) this.initialLoading = false
  }

  setInitialLoading = (loading) => {
    this.initialLoading = loading
  }

  // Profile:
  // --------

  loadProfile = ({ onlyProfile, onSuccess, onError } = {}) => {
    if (this.session && this.session.userId) {
      this.apiCall({
        endpoint: 'item',
        params: {
          id: this.session.userId,
        },
        query: {
          filter: { include: ['roles', { groups: ['role'] }] },
        },
        onSuccess: (data) => {
          this.setUser(data, onlyProfile)
          isFunction(onSuccess) && onSuccess()
        },
        onError,
      })
    }
  }

  loadDataByEntity = ({ endpoint, entityId, onSuccess, onError } = {}) => {
    if (this.session && this.session.userId) {
      this.addPendingOperation()

      this.apiCall({
        endpoint,
        params: {
          id: this.session.userId,
        },
        query: { entityId },
        onSuccess,
        onError,
        onFinish: () => this.removePendingOperation(),
      })
    }
  }

  updateProfile = ({ onSuccess, onError, ...data } = {}) => {
    this.apiCall({
      endpoint: 'update',
      params: {
        id: this.session.userId,
      },
      data,
      onSuccess: () => {
        this.loadProfile({ onlyProfile: true, onSuccess, onError })
      },
      onError,
    })
  }

  updateUserPreferences = (preferences) => {
    this.user.setPreferences(preferences)

    this.updateProfile({ preferences: this.user.preferences })
  }

  // Session:
  // --------

  login = ({ email, password, token, on2FRequired, onSuccess, onError }) => {
    this.addPendingOperation()
    this.apiCall({
      endpoint: 'login',
      data: {
        email,
        password,
        token,
      },
      onSuccess: (data) => {
        if (data.twoFactorRequired) {
          isFunction(on2FRequired) && on2FRequired()
        } else {
          this.setSession(data)
        }

        uiMessage.destroy()

        isFunction(onSuccess) && onSuccess()
      },
      onError,
      onFinish: () => {
        this.removePendingOperation()
      },
    })
  }

  logout = ({ onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'logout',
      onSuccess: () => {
        this.setSession(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  updateSession = ({ error, datetime }) => {
    switch (error) {
      // If there is an error related to an invalid session or
      // an unauthorized access, the session will we nullified.
      case 'AUTHORIZATION_REQUIRED':
      case 'INVALID_TOKEN': {
        this.appStore.setOffline(false)
        this.setSession(null)
        break
      }
      case 'SERVER_OFFLINE': {
        this.appStore.setOffline(true)
        break
      }
      default: {
        const { session } = this
        if (session) {
          // Update session's creation date to extend its duration.
          session.created = datetime
        }
        this.setSessionData(session)
        break
      }
    }
  }

  // Change password:
  // ----------------

  changePassword = ({
    passwordCurrent,
    passwordNew,
    token,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      endpoint: 'changePassword',
      params: {
        id: this.session.userId,
      },
      data: {
        oldPassword: passwordCurrent,
        newPassword: passwordNew,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Set new password:
  // ----------------

  setPassword = ({ passwordNew, token, onSuccess, onError } = {}) => {
    this.setAuthToken(token)

    this.apiCall({
      endpoint: 'resetPassword',
      data: {
        newPassword: passwordNew,
      },
      onSuccess: () => {
        this.setAuthToken(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  // Request password reset:
  // ----------------
  requestPasswordReset = ({ email, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'requestPasswordReset',
      data: { email },
      onSuccess,
      onError,
    })
  }

  // Change Email:
  // ----------------

  changeEmail = ({ newEmail, password, token, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'changeEmail',
      params: {
        id: this.session.userId,
      },
      data: {
        newEmail,
        password,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Change Avatar:
  // ----------------

  changeAvatar = ({
    file,
    crop,
    onUploadProgress,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      endpoints,
      resource: 'avatars',
      endpoint: 'upload',
      params: { id: this.session.userId },
      data: { file, crop: JSON.stringify(crop) },
      uploadFiles: true,
      onUploadProgress,
      onSuccess,
      onError,
    })
  }

  // Two-Factor:
  // -----------

  get2FASecretAndUrl = () => {
    const { email } = this.user
    const secret = getSecret()
    return {
      secret,
      url: getTwoFactorUrl({ email, secret }),
    }
  }

  enable2FA = ({ secret, token, onSuccess, onError }) => {
    // Call the API
    this.apiCall({
      endpoint: 'enable2FA',
      params: {
        id: this.session.userId,
      },
      data: {
        secret,
        token,
      },
      onSuccess: ({ twoFactorCodes }) => {
        onSuccess && onSuccess(twoFactorCodes)
        this.loadProfile({ onlyProfile: true })
      },
      onError,
    })
  }

  disable2FA = ({ password, token, onSuccess, onError }) => {
    this.apiCall({
      endpoint: 'disable2FA',
      params: {
        id: this.session.userId,
      },
      data: {
        password,
        token,
      },
      onSuccess: () => {
        onSuccess && onSuccess()
        this.loadProfile({ onlyProfile: true })
      },
      onError,
    })
  }

  // Entities:
  // --------------
  setCurrentEntity = (currentEntity) => {
    this.currentEntity = currentEntity
    const entityId = currentEntity ? currentEntity.id : null
    setEntityId(entityId)
    this.storeDefaultEntityId(entityId)
    this.setRolesLoaded(false)

    if (entityId) {
      // Load roles
      this.loadDataByEntity({
        endpoint: 'roles',
        entityId,
        onSuccess: (data) => {
          this.setEntityRoles(data)
          this.setRolesLoaded(true)
        },
      })
    } else {
      this.setInitialLoading(false)
      this.setEntityRoles([])
      this.setRolesLoaded(true)
    }
  }

  findEntity = (entities, entityId) => {
    for (const entity of entities) {
      if (entity.id === entityId) return entity

      if (entity.children && entity.children.length > 0) {
        const found = this.findEntity(entity.children, entityId)
        if (found) return found
      }
    }

    return null
  }

  setCurrentEntityById = (entityId) =>
    this.setCurrentEntity(this.findEntity(this.entities, entityId))

  setEntities = (entities) =>
    (this.entities = arrayOf({
      model: Entity,
      withItems: entities,
    }))

  loadEntities = ({ onSuccess }) => {
    this.apiCall({
      endpoint: 'entities',
      params: {
        id: this.session.userId,
      },
      onSuccess: (entities) => {
        this.setEntities(entities)
        isFunction(onSuccess) && onSuccess()
      },
    })
  }

  // Loads entity children, used for the EntityModal
  loadEntityChildren = ({
    parent, // the entity from children will be loaded
    onError, // callback when something went wrong
    onSuccess, // callback when everything runs fine
  }) => {
    // Only proceed if there a parent and has children
    if (parent && parent.hasChildren) {
      // Call the API
      this.apiCall({
        endpoints: entitiesEndpoints,
        resource: 'entities',
        endpoint: 'list',
        query: {
          filter: {
            where: {
              deleted: false,
              parentId: parent.id,
            },
            order: ['name.en-US ASC'], // TODO: Improve this to order by current locale
          },
        },
        onError,
        onSuccess: (rawItems) => {
          // Generate model instances
          const subentities = arrayOf({
            model: Entity,
            withItems: rawItems,
          })

          // Add subentities to parent
          parent.addChildren(subentities)

          if (isFunction(onSuccess)) onSuccess(subentities)
        },
      })
    }
  }

  // Loads entity by ID, if allowed
  loadEntity = ({
    entityId, // the entity ID
  }) =>
    new Promise((resolve) => {
      // Call the API
      this.apiCall({
        endpoint: 'entityById',
        params: {
          id: this.session.userId,
        },
        query: { entityId },
        onSuccess: (entity) => resolve(entity ? new Entity(entity) : null),
        onError: () => resolve(null),
      })
    })

  setDefaultEntity = () => {
    this.addPendingOperation()
    this.loadEntities({
      onSuccess: async () => {
        const defaultEntityId = await this.getDefaultEntityId()

        let defaultEntity

        // try to get the stored entity
        if (defaultEntityId) {
          defaultEntity = this.findEntity(this.entities, defaultEntityId)

          // try to load it from API
          if (!defaultEntity) {
            defaultEntity = await this.loadEntity({ entityId: defaultEntityId })
          }
        }

        // or take the first entity
        if (!defaultEntity)
          defaultEntity = this.entities.length > 0 ? this.entities[0] : null

        if (!defaultEntityId && defaultEntity)
          this.storeDefaultEntityId(defaultEntity.id)

        this.setCurrentEntity(defaultEntity)

        this.removePendingOperation()
      },
    })
  }

  // Local storage:
  // --------------

  storeSession = (session) =>
    localStorage.setItem(sessionKey, session && JSON.stringify(session))

  retrieveSession = () =>
    this.setSession(JSON.parse(localStorage.getItem(sessionKey)))

  storeDefaultEntityId = (entityId) =>
    localStorage.setItem(defaultEntityIdKey, entityId)

  getDefaultEntityId = () => localStorage.getItem(defaultEntityIdKey)

  // Others:
  // -------

  getClientVersion = ({ isInitialLoad = true } = {}) => {
    this.apiCall({
      endpoints,
      resource: 'clients',
      endpoint: 'getVersion',
      onSuccess: (version) => {
        if (isInitialLoad) {
          this.appStore.setVersion(version)
        } else {
          this.appStore.setIsUpToDate(version)
        }
      },
    })
  }
}

decorate(AuthStore, {
  authToken: observable,
  currentEntity: observable,
  entities: observable,
  initialLoading: observable,
  locales: observable,
  pendingOperations: observable,
  rolesLoaded: observable,
  selectedEntityId: observable,
  session: observable,
  user: observable,

  authenticated: computed,
  dataIsLoading: computed,

  addPendingOperation: action,
  removePendingOperation: action,
  setAuthToken: action,
  setCurrentEntity: action,
  setEntities: action,
  setInitialLoading: action,
  setLocales: action,
  setRolesLoaded: action,
  setSelectedEntityId: action,
  setSession: action,
  setSessionData: action,
  setUser: action,
})

export default AuthStore
