import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const TContext = React.createContext()

// Returs a "i18next.t" function configured with markdown-jsx
function markdownT(t) {
  return (key, others) => t(key, { postProcess: 'markdown-jsx', ...others })
}

// Provider
function TContextProvider({ children, ns, useSuspense }) {
  const { t } = useTranslation(ns, { useSuspense })
  return <TContext.Provider value={t}>{children}</TContext.Provider>
}
TContextProvider.propTypes = {
  children: PropTypes.node,
  ns: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  useSuspense: PropTypes.bool,
}

// Hook for context consumer
function useT() {
  return useContext(TContext)
}

// JSX Component
function T({ nsKey, withMarkdown, ...rest }) {
  const t = useContext(TContext)
  return withMarkdown ? markdownT(t)(nsKey, rest) : t(nsKey, rest)
}
T.propTypes = {
  nsKey: PropTypes.string,
  withMarkdown: PropTypes.bool,
}
T.defaultProps = {
  withMarkdown: false,
}

export { useTranslation, markdownT, T, TContextProvider, useT }
