import React, { lazy } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import { Route, Redirect, Switch } from 'react-router-dom'

import {
  admin,
  churchTreasurers,
  projectManagers,
  userManagers,
} from 'config/roles'
import { useAuthContext } from 'modules/account/data/AuthContext'

const DashboardHome = lazy(() => import('modules/dashboard/Home'))
const About = lazy(() => import('modules/About'))
const Help = lazy(() => import('modules/help/Router'))
const NotFound = lazy(() => import('modules/NotFound'))

const ProjectsRouter = lazy(() => import('modules/projects/Router'))
const ReportsRouter = lazy(() => import('modules/reports/Router'))
const EntitiesRouter = lazy(() => import('modules/entities/Router'))

const Profile = lazy(() => import('modules/account/session/Profile'))
const Settings = lazy(() => import('modules/account/settings'))

const GroupsRouter = lazy(() => import('modules/users/groups/Router'))
const Roles = lazy(() => import('modules/users/roles/List'))
const UsersRouter = lazy(() => import('modules/users/users/Router'))

function RouteIfAllowed({ Component, path, roles, redirectTo }) {
  const { user, rolesLoaded } = useAuthContext()

  return (
    <Route path={path}>
      {rolesLoaded ? (
        user.isAllowed(roles) ? (
          <Component />
        ) : (
          <Redirect to={redirectTo} />
        )
      ) : null}
    </Route>
  )
}

RouteIfAllowed.propTypes = {
  authStore: PropTypes.object,
  children: PropTypes.node,
  Component: PropTypes.object,
  path: PropTypes.string,
  redirectTo: PropTypes.string,
  roles: PropTypes.array,
}
RouteIfAllowed.defaultProps = {
  redirectTo: '/',
}

const RouteOrRedirect = observer(RouteIfAllowed)

function PrivateRouter() {
  return (
    <Switch>
      <Route path="/dashboard">
        <DashboardHome />
      </Route>

      <RouteOrRedirect
        path="/projects"
        Component={ProjectsRouter}
        roles={projectManagers}
      />

      <RouteOrRedirect
        path="/reports"
        Component={ReportsRouter}
        roles={churchTreasurers}
      />

      <RouteOrRedirect
        path="/entities"
        Component={EntitiesRouter}
        roles={admin}
      />

      <RouteOrRedirect
        path="/users"
        Component={UsersRouter}
        roles={userManagers}
      />
      <RouteOrRedirect
        path="/groups"
        Component={GroupsRouter}
        roles={userManagers}
      />
      <RouteOrRedirect path="/roles" Component={Roles} roles={admin} />

      <Route path="/user/profile">
        <Profile />
      </Route>
      <Route path="/user/settings/:tab?">
        <Settings />
      </Route>

      <Route path="/help">
        <Help />
      </Route>

      <Route path="/about">
        <About />
      </Route>

      <Redirect from="/index.html" to="/" />
      <Redirect from="/index" to="/" />
      <Redirect from="/" to="/dashboard/home" />

      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default observer(PrivateRouter)
