import { action, computed, decorate, observable } from 'mobx'

import Locale from 'modules/locale/data/Locale'
import Log from 'modules/logs/data/Log'
import { arrayOf } from 'utils/arrays'

import Group from './Group'
import Role from './Role'

import appConf from 'config/app'
const { cdnUrl } = appConf.apiEndpoints

class User {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  firstName = ''
  lastName = ''
  email = ''
  avatar
  avatarUrl
  preferences = {}
  twoFactorEnabled = false
  lastLoginAt
  loginCount = 0
  defaultSourceLocaleId
  locale
  entity
  entityId
  isAdmin = false
  cantDisable2FA = false
  roles = []
  entityRoles = []
  groups = []
  logs = []

  constructor({ groups, locale, logs, roles, ...args }) {
    Object.assign(this, args)

    this.setLocale(locale)
    this.setRoles(roles)
    this.setGroups(groups)
    this.setLogs(logs)
    this.setAvatarUrl()
  }

  // Computed

  get fullName() {
    return `${this.firstName}  ${this.lastName}`
  }

  get profileUrl() {
    return `/users/details/${this.id}`
  }

  get requiresAdmin() {
    for (const role of this.roles) {
      if (role.requiresAdmin === true) return true
    }

    return false
  }

  // Actions
  update = ({ groups, locale, logs, roles, ...args } = {}) => {
    Object.assign(this, args)

    this.setLocale(locale)
    this.setRoles(roles)
    this.setGroups(groups)
    this.setLogs(logs)
    this.setAvatarUrl()
  }

  setEnabled = (value) => (this.enabled = value)

  setDeleted = (value) => (this.deleted = value)

  setLocale = (locale) => (this.locale = locale ? new Locale(locale) : null)

  setRoles = (roles) =>
    (this.roles = roles ? arrayOf({ model: Role, withItems: roles }) : [])

  setEntityRoles = (roles) =>
    (this.entityRoles = roles ? arrayOf({ model: Role, withItems: roles }) : [])

  setGroups = (groups) =>
    (this.groups = groups ? arrayOf({ model: Group, withItems: groups }) : [])

  setLogs = (logs) =>
    (this.logs = logs ? arrayOf({ model: Log, withItems: logs }) : [])

  setPreferences = (newPreferences) => {
    this.preferences = { ...this.preferences, ...newPreferences }
  }

  setAvatarUrl = () => {
    this.avatarUrl = this.avatar
      ? `${cdnUrl}/avatars/${this.id}/${this.avatar}`
      : ''
  }

  // Others
  isAllowed = (roles) => {
    return this.entityRoles.some((role) => roles.includes(role.name))
  }
}

decorate(User, {
  enabled: observable,
  deleted: observable,
  updatedAt: observable,

  avatar: observable,
  avatarUrl: observable,
  cantDisable2FA: observable,
  email: observable,
  entityRoles: observable,
  firstName: observable,
  groups: observable,
  isAdmin: observable,
  lastLoginAt: observable,
  lastName: observable,
  locale: observable,
  loginCount: observable,
  logs: observable,
  preferences: observable,
  roles: observable,
  twoFactorEnabled: observable,

  fullName: computed,
  profileUrl: computed,
  requiresAdmin: computed,

  setAvatarUrl: action,
  setDeleted: action,
  setEnabled: action,
  setEntityRoles: action,
  setGroups: action,
  setLocale: action,
  setLogs: action,
  setPreferences: action,
  setRoles: action,
  update: action,
})

export default User
