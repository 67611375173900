import React, { lazy } from 'react'

import styles from './AppLoading.module.scss'

const LabelIcon = lazy(() => import('ui/LabelIcon'))
const Logo = lazy(() => import('ui/Logo'))

function AppLoading() {
  return (
    <div className={styles.loading}>
      <Logo />

      <div className={styles.loadingSpinner}>
        <LabelIcon label="Loading" icon="spinner" spin />
      </div>
    </div>
  )
}

export default AppLoading
