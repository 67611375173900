import { action, decorate, observable } from 'mobx'

class Country {
  constructor({ ...args } = {}) {
    Object.assign(this, args)
  }

  // Observables:

  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  code = ''
  name = {}

  // Actions:

  // Called by API Store on item's update
  update = ({ name, ...args }) => {
    this.name = name
    Object.assign(this, args)
  }

  setName = (name) => (this.name = name)
}

decorate(Country, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  code: observable,
  name: observable,

  setName: action,
  update: action,
})

export default Country
