import { action, computed, decorate, observable } from 'mobx'

class UIStore {
  // Observers:

  mediaQueries = []
  siderOpen = true

  // Computed

  get xsScreen() {
    return this.mediaQueries.includes('xs')
  }
  get smScreen() {
    return !this.mediaQueries.includes('md+')
  }

  get viewportSize() {
    return this.mediaQueries.filter(mq => !mq.match(/\+/gi))[0]
  }

  // Actions

  setMediaQueries = mediaQueries => {
    this.mediaQueries = mediaQueries
    return this
  }

  setSiderOpen = siderOpen => {
    this.siderOpen = siderOpen
    return this
  }

  toggleSider = () => this.setSiderOpen(!this.siderOpen)

  closeSider = () => this.setSiderOpen(false)
}

decorate(UIStore, {
  mediaQueries: observable,
  siderOpen: observable,

  smScreen: computed,
  viewportSize: computed,
  xsScreen: computed,

  setMediaQueries: action,
  setSiderOpen: action,
})

export default UIStore
