import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'ui/Icon'
import { isFunction } from 'utils/functions'
import { isString } from 'utils/strings'

function LabelIcon({ className, icon, label, labelClass, spin, t }) {
  return (
    <span className={className}>
      {icon && (isString(icon) ? <Icon name={icon} spin={spin} /> : icon)}
      <span className={labelClass}>
        {isString(label) && isFunction(t) ? t(label) : label}
      </span>
    </span>
  )
}

LabelIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  spin: PropTypes.bool,
  t: PropTypes.func,
}
LabelIcon.defaultProps = {
  className: '',
  labelClass: '',
}

export default LabelIcon
