import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isString } from 'utils/strings'

import styles from './Icon.module.scss'

const modes = {
  light: 'fal',
  solid: 'fas',
  regular: 'far',
}

function Icon({
  className,
  marginLeft,
  marginRight,
  mode,
  name,
  noMargin,
  onClick,
  ...rest
}) {
  return (
    <span
      className={`${className} ${styles.wrap} ${
        noMargin
          ? ''
          : marginLeft
          ? styles.marginLeft
          : marginRight
          ? styles.marginRight
          : ''
      }`}
      onClick={onClick}
    >
      {isString(name) ? (
        <FontAwesomeIcon icon={[modes[mode], name]} {...rest} />
      ) : (
        name
      )}
    </span>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  mode: PropTypes.oneOf(Object.keys(modes)),
  name: PropTypes.node.isRequired,
  noMargin: PropTypes.bool,
  ...FontAwesomeIcon.propTypes,
}
Icon.defaultProps = {
  className: '',
  fixedWidth: true,
  marginLeft: false,
  marginRight: true,
  mode: 'regular',
  name: 'exclamation-triangle',
  noMargin: false,
}

export default Icon
