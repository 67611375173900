import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { isFunction } from 'utils/functions'
import { isString } from 'utils/strings'

export { pick, omit, isEmpty, isEqual }

export const isDefined = (object) => object != null

export const isNotNull = (object) => !isNull(object)

export const isNull = (object) => object == null

export const isSet = (object) => isDefined(object) && isNotNull(object)

export const setMap = (object, { setValue = (key, value) => value } = {}) =>
  isObject(object)
    ? new Map(
        Object.keys(object).map((key) => [key, setValue(key, object[key])])
      )
    : null

/**
 * Detects if value is an objects
 * @param any value
 * @returns `boolean`
 */
export const isObject = (value) => !!(isSet(value) && typeof value === 'object')

/**
 * Returns an object's attibute using a key string or a funciton
 * @param object item
 * @param string|function key String used as a key, or Function called to retrive item's attribute
 * @returns `any`
 */
export function getAttribute(object, key) {
  return isFunction(key)
    ? key(object)
    : isString(key) && isObject(object)
    ? object[key]
    : null
}
