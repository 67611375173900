import { action, decorate, observable } from 'mobx'

import i18n from 'config/i18n'
import ApiStore from 'utils/api/ApiStore'
import { arrayOf } from 'utils/arrays'
import Locale from './Locale'
import endpoints from './endpoints'

class LocalesStore extends ApiStore {
  constructor() {
    super({
      model: Locale,
      defaultResource: 'locales',
      paginable: false,
      endpoints,
    })
  }

  // Observers:

  currentLocale = null
  currentLocaleCode = null
  locales = []
  localeUI = 'en-US'
  filters = []

  // Actions

  setLocales = (locales) => {
    this.locales = arrayOf({ model: Locale, withItems: locales })
    return this
  }

  setCurrentLocale = (currentLocale) => {
    this.currentLocale = currentLocale
    return this
  }

  setLocaleUI = (localeUI) => {
    this.localeUI = localeUI
    return this
  }

  // Others

  loadLocales = ({ filter, onSuccess } = {}) => {
    this.getList({
      query: { filter },
      onSuccess: (data) => {
        this.setLocales(data)
        onSuccess && onSuccess(this.locales)
      },
    })
  }

  setCurrentLocaleByCode = (localeCode = 'en-US') => {
    // Set the code (string)
    this.currentLocaleCode = localeCode

    // Get current locale by code
    const currentLocale = this.locales.find(({ code }) => code === localeCode)

    // Set the locale (object)
    this.setCurrentLocale(currentLocale)

    return this
  }

  setLocaleUIByCode = (localeCode = 'en-US') => {
    for (const locale of this.locales) {
      if (locale.code === localeCode) {
        this.setLocaleUI(locale)
        i18n.changeLanguage(locale.languageCode)
      }
    }

    return this
  }
}

decorate(LocalesStore, {
  currentLocale: observable,
  currentLocaleCode: observable,
  locales: observable,
  localeUI: observable,

  setCurrentLocale: action,
  setCurrentLocaleByCode: action,
  setLocales: action,
  setLocaleUI: action,
})

export default LocalesStore
