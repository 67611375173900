import { action, decorate, observable } from 'mobx'

class EntityType {
  constructor({ ...args }) {
    Object.assign(this, args)
  }

  // Observables:

  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  label = null
  name = ''
  isField = false

  // Actions:

  setDeleted = value => (this.deleted = value)

  setEnabled = value => (this.enabled = value)

  // Called by API Store on item's update
  update = ({ name, label, isField }) => {
    this.name = name
    this.label = label
    this.isField = isField
  }
}

decorate(EntityType, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  isField: observable,
  label: observable,
  name: observable,

  setDeleted: action,
  setEnabled: action,
  update: action,
})

export default EntityType
