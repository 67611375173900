import { action, decorate, observable } from 'mobx'

import { arrayOf } from 'utils/arrays'

import User from './User'
import Role from './Role'

class Group {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  name = {}
  entity // Main entity
  entityId
  role
  roleId
  requiresAdmin = false
  requires2FA = false

  accounts = [] // a.k.a. "Users". Array of Users objects
  candidates = [] // a.k.a. "candidate users". Array of Users objects

  constructor({ accounts, role, ...args } = {}) {
    Object.assign(this, args)

    // this.entity = new Entity(entity)
    this.role = new Role(role)

    this.setAccounts(accounts)
  }

  // Actions
  update = ({ name, roleId }) => {
    this.name = name
    this.roleId = roleId
  }

  setEnabled = (value) => (this.enabled = value)
  setDeleted = (value) => (this.deleted = value)

  // - Accounts

  setAccounts = (accounts) => {
    this.accounts = arrayOf({ model: User, withItems: accounts })
  }

  removeAccounts = (removeUserIds) => {
    this.setAccounts(
      this.accounts.filter(({ id }) => !removeUserIds.includes(id))
    )
  }

  // - Candidates

  setCandidates = (accounts) => {
    this.candidates = arrayOf({ model: User, withItems: accounts })
  }
}

decorate(Group, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  accounts: observable,
  candidates: observable,
  entity: observable,
  entityId: observable,
  name: observable,
  role: observable,
  roleId: observable,
  requiresAdmin: observable,
  requires2FA: observable,

  setAccounts: action,
  setCandidates: action,
  setDeleted: action,
  setEnabled: action,
  update: action,
})

export default Group
