import React, { lazy, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router'

import useSideMenu from 'config/useSideMenu'
import { flatten } from 'utils/arrays'
import { useTranslation } from 'utils/TranslateUI'

const Menu = lazy(() => import('ui/Menu'))

function getCurrentKeys(path) {
  const posibleUrls = path.match(/\/([^/]+)?/g).reduce((acc, current, i) => {
    acc[i] = (acc[i - 1] || '') + current
    return acc
  }, [])

  return flatten(posibleUrls.map(url => [url, `${url}/`]))
}

function SiderMenu() {
  const { pathname } = useLocation()
  const { t } = useTranslation(['menu', 'dashboard'])
  const items = useSideMenu()

  // Calculate the open/selected keys
  const currentKeys = useMemo(() => getCurrentKeys(pathname), [pathname])

  return (
    <Menu
      defaultOpenKeys={currentKeys}
      selectedKeys={currentKeys}
      mode="inline"
      inlineIndent={16}
      items={items}
      t={t}
    />
  )
}

export default observer(SiderMenu)
