import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useLocalStore } from 'mobx-react'

import { isObject } from 'utils/objects'
import { useSelectOptions } from 'utils/arrays'

import LocalesStore from './LocalesStore'

// Contexts
export const LocalesContext = React.createContext()

// Provider
export function LocalesContextProvider({ children }) {
  const store = useLocalStore(() => new LocalesStore())

  return (
    <LocalesContext.Provider value={store}>{children}</LocalesContext.Provider>
  )
}

LocalesContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useLocalesContext() {
  return useContext(LocalesContext)
}

export function useTranslate() {
  const localesStore = useLocalesContext()
  const { currentLocale, localeUI } = localesStore

  /**
   * Functional version of Utils/Translate (usage requires a mobx's observer)
   *
   * Returns text based on this order priority:
   *
   * 1. Requested language
   * 2. Default Content language
   * 3. User Interface language
   * 4. English
   * 5. First of content's languages
   *
   * Params:
   *
   * @param {object} content (object, required) An object with locales as `keys` and text as `values`
   * @param {object} options (object) It supports one or more of the following settings:
   * - `language` `(string)` Use this to force a specific locale
   * - `uiFirst` `(boolean)` If `true`, sets the UI Locale as second in priority.
   * - `fallbackContent` `(string)` Content shows when everything else fails.
   * - `t` `(function)` Translation function used when content is not an object.
   */
  function translate(
    content,
    {
      language = null,
      uiFirst = false,
      modifier = (text) => text,
      fallbackContent = '',
      t = (x) => x,
    } = {}
  ) {
    if (!isObject(content)) return t(`${content}`)

    const firstLocaleWithContent = Object.keys(content).filter((key) =>
      Boolean(content[key])
    )[0]

    return modifier(
      localeUI && currentLocale.code
        ? content[language] ||
            content[uiFirst ? localeUI.code : currentLocale.code] ||
            content[uiFirst ? currentLocale.code : localeUI.code] ||
            content['en-US'] ||
            content[firstLocaleWithContent] ||
            fallbackContent
        : fallbackContent
    )
  }

  return translate
}

// Returns the locales as ui/Select options
export function useLocalesOptions({ value = 'id' } = {}) {
  const localesStore = useLocalesContext()
  const translate = useTranslate()

  return useSelectOptions(localesStore.locales, {
    label: (locale) => translate(locale.name),
    value,
  })
}
