import { action, decorate, observable } from 'mobx'

const initialState = {
  loading: false,
  offline: false,
  reconnected: false,
}

class AppStore {
  // Observables:

  lastApiCall = null
  lastApiError = null
  loading = false
  offline = false
  reconnected = false
  isUpToDate = true
  version = '1.0.0'

  constructor(args) {
    Object.assign(this, { ...initialState, ...args })
  }

  // Actions

  setLastApiCall = (error) => {
    const now = new Date()
    const isEnoughWaitTime = +now > +this.lastApiCall + 2000

    if (!this.lastApiCall || isEnoughWaitTime) {
      this.lastApiCall = now
    }

    this.lastApiError = error

    return this
  }

  setLoading = (loading) => {
    this.loading = loading
    return this
  }

  setOffline = (offline) => {
    this.offline = offline
    return this
  }

  setReconnected = (reconnected) => {
    this.reconnected = reconnected
    return this
  }

  setVersion = (version) => {
    this.version = version
    return this
  }

  setIsUpToDate = (version) => (this.isUpToDate = this.version === version)
}

decorate(AppStore, {
  lastApiCall: observable,
  lastApiError: observable,
  loading: observable,
  offline: observable,
  reconnected: observable,
  isUpToDate: observable,
  version: observable,

  setIsUpToDate: action,
  setLastApiCall: action,
  setLoading: action,
  setOffline: action,
  setReconnected: action,
  setVersion: action,
})

export default AppStore
