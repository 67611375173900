import { action, computed, decorate, observable } from 'mobx'

import Country from './Country'
import EntityType from './EntityType'
import Group from 'modules/users/data/Group'
import { arrayOf } from 'utils/arrays'

class Entity {
  constructor({
    name,
    config,
    country,
    entityType,
    parent,
    ancestors,
    children,
    groups,
    ...args
  } = {}) {
    Object.assign(this, args)

    this.setName(name)
    this.setCountry(country)
    this.setEntityType(entityType)
    this.setParent(parent)
    this.config = {
      ...(config || {}),
    }
    this.ancestors = arrayOf({ model: Entity, withItems: ancestors })
    this.children = arrayOf({ model: Entity, withItems: children })
    this.groups = arrayOf({ model: Group, withItems: groups })
  }

  // Observables:

  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  code = ''
  name = {}
  config = {}
  entityType = null
  entityTypeId = null
  country = null
  countryId = null
  parent = null
  parentId = null
  ancestors = null
  children = null
  hasChildren = false
  groups = null

  // Computed:

  get visible() {
    return this.enabled && !this.deleted
  }

  // Actions:

  // Called by API Store on item's update
  update = ({ ...args }) => {
    Object.assign(this, args)
  }

  setEnabled = (value) => (this.enabled = value)
  setDeleted = (value) => (this.deleted = value)

  setCode = (code) => (this.code = code)

  setName = (name) => (this.name = name)

  setEntityType = (type) =>
    (this.entityType = type ? new EntityType(type) : null)

  setCountry = (country) =>
    (this.country = country ? new Country(country) : null)

  setParent = (parent) => (this.parent = parent ? new Entity(parent) : null)

  setAncestors = (ancestors) => (this.ancestors = ancestors)

  addChildren = (children) => (this.children = children)

  addChild = (child) => this.children.push(child)

  setGroups = (groups) =>
    (this.groups = arrayOf({
      model: Group,
      withItems: groups,
    }))
}

decorate(Entity, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  ancestors: observable,
  children: observable,
  country: observable,
  countryId: observable,
  groups: observable,
  hasChildren: observable,
  code: observable,
  name: observable,
  parent: observable,
  parentId: observable,

  visible: computed,

  addChild: action,
  addChildren: action,
  setAncestors: action,
  setCountry: action,
  setDeleted: action,
  setEnabled: action,
  setEntityType: action,
  setGroups: action,
  setCode: action,
  setName: action,
  setParent: action,
  update: action,
})

export default Entity
