import { isString, withEveryMatch } from './strings'

/**
 * Extracts parameters from a query string
 *
 * @param {string} query
 *
 * @returns object
 */
export function getParameters(query = '') {
  const out = {}
  const doReplace = (match) => (out[match[1]] = match[2])
  withEveryMatch(query, /(?:\?|&amp;|&)([^=]+)=([^&#]+)/, doReplace)
  return out
}

/**
 * Looks for a parameter in location's search and retrieves its value
 * @param {Location} location
 * @param {String} name
 *
 * @returns {String} value
 */
export function getQueryParameter(location, name) {
  const searchParams = new URLSearchParams(location.search)

  return searchParams.get(name)
}

/**
 * Replaces params (`:param`) in a url string.
 *
 * Example:
 *   `url = /items/:id` and `replacements = { id: '123' }` => `/items/123`
 *
 * @param {string} url
 * @param {object} replacements
 *
 * @returns string
 */
export function replaceParams(url, replacements) {
  let out = url
  const doReplace = (match) =>
    (out = out.replace(match[0], replacements[match[1]]))
  withEveryMatch(url, /{(\w+)}/, doReplace)
  return out
}

/**
 * Given a url (`string`) checks if it ends in a query divider (`?`) and adds it if required
 * @param {string} url
 *
 * @returns string
 */
export function getUrlWithQueryDivider(url) {
  return url ? url + (url.match(/\?/i) !== null ? '&' : '?') : ''
}

/**
 * Checks if provided string starts with a protocol that matches a external url
 * Supports `http(s):`, `ftp(s):`, `mailto:`, `tel:` and `sms:`,
 * @param {string} value
 */
export function isExternalUrl(value) {
  return isString(value)
    ? /^(:\/\/)/.test(value) || // ://
        /^(f|ht)tps?:\/\//i.test(value) || // http(s) or ftp(s)
        /^(mailto|tel|sms):/i.test(value) // Email, Phone or SMS
    : false
}
